import DataCard from "./DataCard";
import React, {useContext} from "react";
import SinglesContext from "../../../../datamanager/contexts/singles";
import { formatNumber, truncateDecimal } from "../../../../utils/FormatValues";
import { useStats } from "../../../../datamanager/contexts/Stats";
import DataCardFooterEvolution from "./DataCardFooterEvolution";
import DataCardFooterSimple from "./DataCardFooterSimple";
import SalesChart from "../../dashboard/components/SalesChart";
import {salesData} from "../../../../utils/Constants";
import translate from "../../../../i18n/translate";

export default function DataCardList() {

  const { singles } = useContext(SinglesContext);
  const { stat } = useStats();

  console.log(stat);
  console.log(singles);

  const datas = [
    {
      title: translate("Lectures"),
      value: stat ? stat.nb_listenning ? formatNumber(stat.nb_listenning) : 0 : 0,
      infoTooltip: null,
      foot: <DataCardFooterEvolution value={stat ? stat.nb_listenning ? formatNumber(stat.nb_listenning) : 0 : 0}  periodicity={translate("thisMonth")} variationDirection={"top"} />
    },
    {
      title: translate("musicSales"),
      value: stat  ? stat.total_album_sold ? formatNumber(stat.total_album_sold) : 0 : 0,
      infoTooltip: null,
      foot: <DataCardFooterEvolution value={stat  ? stat.total_album_sold ? formatNumber(stat.total_album_sold) : 0 : 0}  periodicity={translate("thisMonth")} variationDirection={"down"} />
    },
    {
      title: translate("IncomesEstimation"),
      value: stat ? stat.total_album_sales ? formatNumber(stat.total_album_sales) : 0 : 0,
      infoTooltip: null,
      foot: <DataCardFooterSimple value={"FCFA"} />
    },
    {
      title: translate("relistenningRate"),
      value: stat ? stat.nb_listenning ? stat.nb_uniq_listenning ? `${truncateDecimal((stat.nb_uniq_listenning / stat.nb_listenning))}%` : 0 : 0 : 0,
      infoTooltip: "taux de reecoute",
      foot: <SalesChart salesData={salesData} display_y={false} display_x={false} display_label={false} pointRadius={0}/>
    }
  ];

  return (
    <div className="monit flex flex-wrap">
      {
        datas.map((data, index) => (
          <DataCard
            key={index}
            title={data.title}
            value={data.value}
            infoTooltip={data.infoTooltip}
            foot={data.foot}
          />
        ))
      }
    </div>
  );
}