import React, { useContext, useEffect, useState } from "react";
import gsap from "gsap";
import SinglesContext from "../../../datamanager/contexts/singles";
import AlbumsContext from "../../../datamanager/contexts/albums";
import translate from "../../../i18n/translate";
import "./styles/dashboard.module.css"
import Loader from "../../../components/Loader";
import NoContent from "../../../components/NoContent";
import GreedUser from "./components/GreedUser";
import DataCardList from "../audio/component/DataCardList";
import DataChart from "./components/DataChart";
import { useLoadingContext } from "../../../datamanager/contexts/locals/Dashboard/loading";
import { transformUrl } from "../../../utils/functions";
import CurrentUserContext from "../../../datamanager/contexts/currentUser";

import bell from '../../../assets/icons/bell.svg';
import dashboard from '../../../assets/icons/dashboard.svg';

import download from '../../../assets/icons/download.svg';
import back from '../../../assets/icons/ArrowLeft.svg'
import disconnect from '../../../assets/icons/disconnect.svg'

import { Link } from "react-router-dom";
import SideBarProfile from "../../../components/SideBarComponents/SideBarProfile";
import DashTopNav from "../../../components/DashTopNav/DashTopNav";

function Dashboard() {
  const [open, setOpen] = useState(false);

  const { currentUser } = useContext(CurrentUserContext);
  // Get data from the global context
  const { singles } = useContext(SinglesContext);
  const { albums } = useContext(AlbumsContext);

  const { loading, handleSetLoading } = useLoadingContext();

  const handleOpen = () => {
    gsap.fromTo(
      "#mob-nav",
      {
        x: '-100%',
      },
      {
        x: '0',
        duration: 0.75,
      }
    );
    setOpen(!open)
  }

  // UseEffect section
  useEffect(() => {
    if (singles && albums) {
      handleSetLoading(false)
    }
  }, [singles, albums])


  return (
    <>
      {loading ? <Loader /> :
        albums.count || singles.count ? (
          <div className="main-dash">
            <DashTopNav />

            <div className="p-6">
              <GreedUser />
            </div>
            <div className="datas">
              <div className="main-side">
                <span className="main-side-title">{translate('yourdata')}</span>
                <DataCardList />
                <div className="main-side-elt">
                  <span className="main-side-title">{translate('ouraudience')}</span>
                  <DataChart />
                </div>
              </div>
            </div>
          </div>
        ) : <NoContent path={"/app/publish/select-content-type"} contentType={"album ou audio"} />}
    </>
  );
}

export default Dashboard;
