import React, { useContext } from "react";
import { useEffect, useState } from "react";
import "../../../styles/App.css";
import SinglesContext from "../../../datamanager/contexts/singles";
import "./styles/single.module.css";
import translate from "../../../i18n/translate";
import Search from "../../../components/baseComponents/Search";
import YourData from "../../../components/YourData";
import { SwitchUnderlinedProvider } from "../../../datamanager/contexts/locals/switchUnderlined";
import Loader from "../../../components/Loader";
import NoContent from "../../../components/NoContent";
import Greed from "../dashboard/components/Greed";
import { useLoadingContext } from "../../../datamanager/contexts/locals/Dashboard/loading";
import AlbumsContext from "../../../datamanager/contexts/albums";
import DashTopNav from "../../../components/DashTopNav/DashTopNav";

function Audio() {

  const { loading, handleSetLoading } = useLoadingContext();

  // Get data from the global context
  const { singles } = useContext(SinglesContext);
  const { albums } = useContext(AlbumsContext);
  // UseEffect section
  useEffect(() => {
    if (singles && albums) {
      handleSetLoading(false)
    }
  }, [singles, albums])


  return (
    <>
      {
        loading ? <Loader /> :
          <div className="main-dash" style={{ backgroundColor: 'black' }}>

            <DashTopNav />

            <div className="p-6">
              <Greed content1={translate('your_music')} content2={translate('welcome')} />
            </div>
            <SwitchUnderlinedProvider>
              <YourData />
            </SwitchUnderlinedProvider>
          </div>
      }
    </>
  );
}

export default Audio;
