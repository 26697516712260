import React from 'react'

const Button = ({text, width, icon, bg='#FF5620', border}) => {
    return (
        <button className='flex justify-center items-center h-[44px] rounded-3xl px-6 py-4' style={{width: width, background: bg, border: border}}>
            <span className='text-white mr-1'>{text}</span>
            {icon}
        </button>
    )
}

export default Button