import up from "../../../../assets/new_images/Up.svg";
import down from "../../../../assets/new_images/Down.svg";
import React from "react";
import {formatNumber} from "../../../../utils/FormatValues";
import AlbumEltCover from "./AlbumEltCover";

export default function AlbumElt({ album, evolution, index }) {
  return (
    <div className="topfan-titles">
      <span className="topfan-r">
        <span className="text-gray-600">{index + 1}</span>
        <AlbumEltCover album={album}  />
        <span className="topfan-r1 text-xs">
          <span className="text-xs">{album.title}</span>
        </span>
      </span>
      <span className="text-xs text-gray-600 font-bold">{formatNumber(album.nb_of_listening)}</span>
      <span className="text-xs text-gray-600 font-bold">+10%</span>
      <span className="topfan-r">{index}<img alt={"evo"} className="topsingvalimg" src={evolution === "up" ? up : down}/></span>
    </div>
  );
}