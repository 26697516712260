import translate from '../../../i18n/translate'
import '../../App.css';
import styles from '../styles/footer.module.css'
import logotyp from "../../../assets/new_images/logo_typo.svg"
import ordi from "../../../assets/new_images/ordi.svg"
import playstore from "../../../assets/new_images/playstore.svg"
import applestore from "../../../assets/new_images/applestore.svg"
import facebook from "../../../assets/new_images/facebook.svg"
import tiktok from "../../../assets/new_images/tiktok.svg"
import x from "../../../assets/new_images/x.svg"
import instagram from "../../../assets/new_images/instagram.svg"
import { HashLink as Linka } from 'react-router-hash-link';
import { useLanguage } from "../../../datamanager/contexts/Language";
import { LOCALES } from "../../../i18n";
import english from "../../../assets/new_images/english.svg";
import french from "../../../assets/new_images/french.svg";
import arabic from "../../../assets/new_images/arabic.svg";
import toast, { Toaster } from 'react-hot-toast';
const Footer = () => {
  const notify = () => toast("Bientôt disponible");

  const { language, languageList, updateHotLanguage } = useLanguage();


  const selectedFlag = (() => {
    switch (language) {
      case LOCALES.ENGLISH:
        return english;
      case LOCALES.FRANCAIS:
        return french;
      case LOCALES.ARABE:
        return arabic;
      default:
        return null; // Handle default case if necessary
    }
  })();

  return (
    <div className="fulll">
      <div className="fond6">

        <div className={`foooter ${styles.footerContainer}`}>


          <div className={`foooter1 ${styles.footerContainer1}`}>

            <div className={styles.footerBlock}>
              <a className='ftr-logoo' href="http://artists.colorfol.com/">
                <img className="ftr-logoo-img" src={logotyp} alt="floatings-icons" />
              </a>
            </div>

            <div className={styles.footerBlock}>
              <div className={`ftr-contentt ${styles.footerContent}`}>
                {/* <h3>{translate('ft_fmt')}</h3> */}
                <h3>{translate('getstart')}</h3>

                <ul>
                  <li><a href="http://colorfol.com/download">{translate('downapp')}</a></li>
                  <li><a href="#3" onClick={notify}>{translate('subpricing')}</a></li>
                  <li><a href="#3" onClick={notify}>{translate('help')}</a></li>
                </ul>
              </div>
            </div>

            <div className={styles.footerBlock}>
              <div className={`ftr-contentt ${styles.footerContent}`}>
                <h3>{translate('diss')}</h3>

                <ul>
                  <li><a href="#3">{translate('colart')}</a></li>
                  <li><a href="#3" onClick={notify}>{translate('fmt_ti')}</a></li>
                  <li><a href="#3" onClick={notify}>{translate('fmt_fiii')}</a></li>
                  <li><a href="#3" onClick={notify}>{translate('fmt_fii')}</a></li>
                </ul>
              </div>
            </div>

            <div className={styles.footerBlock}>
              <div className={`ftr-contentt ${styles.footerContent}`}>
                <h3>{translate('ourcompany')}</h3>

                <ul>
                  <li><a href="#3"  onClick={notify}>{translate('waw')}</a></li>
                  <li><a href="#3"  onClick={notify}>{translate('ov')}</a></li>
                  <li><a href="#3"  onClick={notify}>{translate('ot')}</a></li>
                </ul>

                <h3>{translate('fu')}</h3>

                <div className="follow">
                  <a href="http://instagram.com/colorfolappci">
                    <img alt={"tooltip"} src={instagram} className="links1" />
                  </a>
                  <a href="http://x.com/colorfolapp">
                    <img alt={"tooltip"} src={x} className="links1" />
                  </a>
                  <a href="http://facebook.com/colorfolapp">
                    <img alt={"tooltip"} src={facebook} className="links1" />
                  </a>
                  <a href="http://tiktok.com/colorfolapp">
                    <img alt={"tooltip"} src={tiktok} className="links1" style={{backgroundColor:"white", padding:"0"}}/>
                  </a>
                </div>

              </div>
            </div>

            <div className={styles.footerBlock}>
              <div className={`ftr-contentt2 ${styles.footerContent}`}>
                <h3>{translate('ft_smt')}</h3>

                <ul>
                  <li><a href="#3"><span className="cont">{translate('smt_sei')}</span></a></li>
                  <li><a href="#3">{translate('smt_hui')}</a></li>
                  <li><a href="#3">{translate('smt_nei')}</a></li>
                  <li><a href="#3"><span className="cont">{translate('smt_fi')}</span></a></li>
                  <li><a href="#3">{translate('smt_si')}</a></li>
                  <li><a href="#3">{translate('smt_ti')}</a></li>
                  <li><a href="#3"><span className="cont">{translate('smt_foi')}</span></a></li>
                  <li><a href="#3">{translate('smt_fii')}</a></li>
                  <li><a href="#3">{translate('smt_sii')}</a></li>
                </ul>
              </div>
            </div>

            <div className={styles.footerBlock}>
              <div className={`ftr-contentt3 ${styles.footerContent}`}>
                <img alt={"tooltip"} src={ordi} className="links5 " />
                <div className='flex gap-2'>
                  <a href="download"><img src={applestore} alt='appstore thumb' /></a>
                  <a href="download"><img src={playstore} className="links" alt='playstore thumb' /></a>

                </div>
              </div>
            </div>
          </div>

          <div className="copywright">
            <div className="legals gap-10">
              <span className="cpwright">{translate('copyright')}</span>
              <Linka to="cgu"><span className="terms">{translate('terms')}</span></Linka>
              <Linka to="privacy-policy"><span className="privacy">{translate('privacy')}</span></Linka>
              <div className="flex-1 flex justify-end items-center">
                <div className="languages ">
                  <div className="langg"><img alt={"lang"} className="langgimg" src={selectedFlag} /></div>
                  <select className='lsdro1' value={language} onChange={updateHotLanguage} id="languages">
                    {
                      languageList.map((item) => (
                        <option key={item.code} value={item.value}>
                          {item.code}
                        </option>
                      ))
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Toaster
        // toastOptions={{
        //   style: {
        //     color: '#713200',
        //     backgroundColor:'skyblue'
        //   },
        //   success: {
        //     style: {
        //       background: 'green',
        //     },
        //   },
        //   error: {
        //     style: {
        //       background: 'red',
        //     },
        //   },
        // }}
         />
    </div>
  )
}
export default Footer