import React, { useState } from 'react'
import Button from '../Button/Button'
import translate from '../../i18n/translate'

import logo from '../../assets/logo/logo.png'
import toggler from '../../assets/icons/List.svg'
import back from '../../assets/icons/ArrowLeft.svg'
import { Link } from 'react-router-dom'
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import './navbar.scss'
const Navbar = () => {
    const [open, setOpen] = useState(false);

    const links = [
        {
            id: 1,
            name: translate("disc"),
            link: 'http://store.colorfol.com/'
        },
        {
            id: 2,
            name: translate("prem"),
            link: '/'
        },
        {
            id: 3,
            name: translate("colart"),
            link: 'http://artists.colorfol.com/'
        },
    ]
    const handleOpen = () => {
        if (open) {
            gsap.fromTo(
                "#mob-nav",
                {
                    x: '0',
                },
                {
                    x: '100%',
                    duration: 0.75,
                }
            );
        } else {

            gsap.fromTo(
                "#mob-nav",
                {
                    x: '100%',
                },
                {
                    x: '0',
                    duration: 0.75,
                }
            );
        }
        setOpen(!open)
    }

    return (
        <>
            <main className='navbar fixed w-4/5 lg:w-3/4 h-[140px] py-6 mx-auto flex  items-center'>
               <a href="http://artists.colorfol.com/"><img src={logo} alt="Logo" /></a> 

                <nav className='web-nav flex justify-end items-center gap-5 w-auto flex-1 bg-transparent'>
                    {
                        links.map((link, index) => (
                            <Link to={link.link} key={index} className='px-6 py-4'>
                                {link.name}
                            </Link>
                        ))
                    }
                </nav>


                <a href="http://colorfol.com/download" className='download'><Button text={translate('freedown')} /></a>

                <div className="toggler flex flex-1 justify-end items-center">
                    <img src={toggler} alt="toggler" onClick={handleOpen} />
                </div>
            </main>


            <nav id="mob-nav" className='mobile-nav z-10 flex flex-col justify-start items-start gap-5 w-full h-full fixed top-0 left-0' style={open ? { display: 'flex' } : { display: 'none' }}>
                <img src={back} alt="back icon" onClick={handleOpen} />
                <div className="auth w-full flex flex-col">
                    <Link to='/app/access'>{translate('SignIn')}</Link>
                    <Link to='/start/music'>{translate('create')}</Link>
                </div>
                <div className="links w-full flex flex-col">
                    {
                        links.map((link, index) => (
                            <Link to={link.link} key={index}>
                                {link.name}
                            </Link>
                        ))
                    }
                    <Link to='http://colorfol.com/download'>
                        {translate('freedown')}
                    </Link>

                </div>
            </nav>
        </>
    )
}

export default Navbar